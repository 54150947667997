import './App.css';
import {Alert, Badge, Button, Card, Container, Image, Stack, Table, Toast, ToastContainer} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {useSearchParams} from "react-router-dom";
import axios from "axios";

function App() {
    const [searchParams, setSearchParams] = useSearchParams();
    const [load, setLaod]= useState(null)
    const [data, setData]= useState(null)
    const akey = searchParams.get('akey');

    useEffect(() => {
        const fetchData = async () => {
            await axios.get(`${process.env.REACT_APP_END_POINT_URL_WAREHOUSE}/sporders/?akey=${akey}`).then((res)=>{
                if (res.data.data) {
                    setLaod(true)
                    setData(res.data.data)
                } else {
                    setLaod(false)
                }

            }).catch(()=>{
                setLaod(false)
            })

        }
        fetchData()

    }, [])
    return (
        <div>
            <Container fluid className="p-3">
                <OrderContent load={load} data={data}/>
            </Container>
        </div>
    );
}
function OrderContent(props) {
    if (props.load===false) {
        return <NotFound/>
    } else if(props.load===true) {
        return <ShowData data={props.data}/>
    }
    return '';
}
function NotFound() {
    return(<h3 className="header">Toks užsakymas nerastas!</h3>);
}
function ShowData(props) {
    const [inputData, setInputData]=useState([])
    const [showSuccess, setShowSuccess]=useState(props.data.order.statusBySupplier)
    const [comment, setComment] = useState('')
    const [showToast, setShowToast]=useState(false)
    const [showToastError, setShowToastError]=useState(false)
    const translate={
        LT:{
            ks:'Prekės ID',
            reference:'Užsakymo nr.',
            createdAt: 'Užsakymo data',
            supplierReference:'Tiek. prekės kodas',
            productBarcode:'Barkodas',
            productName:'Prekė',
            quantityOrdered:'Kiekis',
            brand:'Brendas',
            supplierNote: 'Pastabos',
            submit:'Patvirtinti užsakymą',
            downlodxls: 'Atsisiųsti Excel formatu',
            orderConfirm:'Užsakymas patvirtintas',
            orderNote: 'Komentaras',
            orderSubmitError: 'Nepavyko patvirtinti užsakymo!',
            importantNote:'Svarbu! Jei neturite prekės likučio, pastabose nurodykite planuojama likučio papildymo data (pvz rugsėjo pabaigoje arba 09-24). Jei prekės nebeturite asortimente nurodykite "Nebebus" arba "Nebegamina"'
        },
        EN:{
            ks:'Product ID',
            reference:'Order No',
            createdAt: 'Order date',
            supplierReference:'Supplier reference',
            productBarcode:'Barcode',
            productName:'Name',
            quantityOrdered:'Qty',
            brand:'Brand',
            supplierNote: 'Notes',
            submit:'Confirm order',
            downlodxls: 'Download Excel',
            orderConfirm:'Order confirm',
            orderNote: 'Comment',
            orderSubmitError: 'Nepavyko patvirtinti užsakymo!',
            importantNote:'Important! If you do not have the item in stock, please indicate in the notes the planned restock date (e.g. end of September or 09-24). If the item is no longer produced or supplied by the manufacturer, please indicate \'Discontinue\' or \'Out of production\' or \'No longer available\'.'
        }
    }

    const handleChange= (id, index) => (e) => {
        setInputData({...inputData, [id]:e.target.value})
        props.data.detail[index]['supplierNote']=e.target.value;
    }
    const handleComment= (val) => {
        setComment(val)
        props.data.order.supplierNote=val
    }

    const downloadXls = () => {
        window.location.href = `${process.env.REACT_APP_END_POINT_URL_WAREHOUSE}/sporders/${props.data.order.akey}/xls`
    }

    function submitOrder() {
        window.scrollTo(0, 0)
        return axios.post(`${process.env.REACT_APP_END_POINT_URL_WAREHOUSE}/sporders/submit?akey=${props.data.order.akey}`, {
            detail: inputData,
            comment: comment
        }, {
            headers: {
                accept: 'application/ld+json',
                'Content-Type': 'application/ld+json'
            }
        }).then((res)=>{
            if (res.data.success) {
                setShowToast(true);
                setShowSuccess(true);
            }
        }).catch(()=>[
            setShowToastError(true)
        ])
    }

    return(
        <Card>
            <Card.Header>GSS Baltic</Card.Header>
            <Card.Body>
                <Alert show={showSuccess} variant="success">
                    <span>{translate[props.data.order.lang].orderConfirm}</span>
                </Alert>
                <ToastContainer position="top-end">
                    <Toast onClose={() => setShowToast(false)} show={showToast} delay={3000} autohide bg="success">
                        <Toast.Body className="text-white" >{translate[props.data.order.lang].orderConfirm}!!!</Toast.Body>
                    </Toast>
                    <Toast onClose={() => setShowToastError(false)} show={showToastError} delay={3000} autohide bg="error">
                        <Toast.Body className="text-white" >{translate[props.data.order.lang].orderSubmitError}!!!</Toast.Body>
                    </Toast>
                </ToastContainer>


                <Card.Title>
                    <Stack direction="horizontal" gap={2}>
                        <div>{translate[props.data.order.lang].reference} {props.data.order.reference}</div>
                        <div className="ms-auto">{translate[props.data.order.lang].createdAt}: {props.data.order.createdAt}</div>
                    </Stack>
                     </Card.Title>
                <Card.Subtitle className="mb-2 text-danger">{translate[props.data.order.lang].importantNote}</Card.Subtitle>
                <Table striped bordered hover>
                    <thead>
                    <tr>
                        <th>#</th>
                        <th>{translate[props.data.order.lang].ks}</th>
                        <th>{translate[props.data.order.lang].supplierReference}</th>
                        <th>{translate[props.data.order.lang].productBarcode}</th>
                        <th>{translate[props.data.order.lang].brand}</th>
                        <th>{translate[props.data.order.lang].productName}</th>
                        <th>{translate[props.data.order.lang].quantityOrdered}</th>
                        <th>{translate[props.data.order.lang].supplierNote}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {props.data.detail.map((val,index)=>(
                        <tr key={index}>
                            <td width={100}><Image src={val.imageUrl} width={80} thumbnail={true}/> </td>
                            <td  width={110}>{val.ks}</td>
                            <td>{val.supplierReference}</td>
                            <td>{
                                val.productBarcode.split(",").map((item, idx) =>(
                                    <span key={idx}>
                                            {item}
                                        <br/>
                                            </span>
                                ))
                            }</td>
                            <td>{val.brand}</td>
                            <td>{val.productName}</td>
                            <td><Badge bg="danger"><strong>{val.quantityOrdered}</strong></Badge></td>
                            <td width={450} ><input type="text" key={index} disabled={props.data.order.status} className="form-control" value={val.supplierNote} onChange={handleChange(val.id,index)}
                                       placeholder=""/></td>
                        </tr>

                    ))}
                    </tbody>
                </Table>
                <p>
                    <label htmlFor="comment" className="form-label">{translate[props.data.order.lang].orderNote}:</label>
                    <textarea name="comment" disabled={props.data.order.status} className="form-control" value={props.data.order.supplierNote} rows={2} onChange={e => handleComment(e.target.value)}/>
                </p>
                <div className={"float-start"}>
                    <Button variant="warning" onClick={downloadXls}>{translate[props.data.order.lang].downlodxls}</Button>
                </div>
                <div className="float-end">

                    {!props.data.order.status &&
                    <Button variant="success" onClick={submitOrder}>{translate[props.data.order.lang].submit}</Button>}
                </div>

            </Card.Body>
        </Card>
    )
}

export default App;
